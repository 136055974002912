
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    FabComponent: () => import("@/app/modules/whiteboard/components/Fab.vue"),
    PersonalPost: () => import("@/app/modules/whiteboard/components/MyPost.vue")
  }
})
export default class MyPostsPage extends Vue {
  lazyLoaded: number[] = [];

  get personalPostsData() {
    return this.$store.getters["posts/personalData"];
  }

  created() {
    if (!this.personalPostsData) this.fetchPosts();
  }

  fetchPosts() {
    this.$store.dispatch("posts/fetchPersonal").then(({ data }) => {
      if (!this.personalPostsData) this.$store.commit("posts/setPersonalData", [...data.data]);
      else this.$store.commit("posts/setPersonalData", [...this.personalPostsData, ...data.data]);
    });
  }
}
